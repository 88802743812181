import React from "react";

export default function Rules() {
  return (
    <div className="container h-100">
      <div>
        <h2>Rules of Speaking</h2>
        <ol>
          <li>
            All Members have the rights to speak at the HOA Board meetings
          </li>
          <li>
            Renters or other non-homeowners of the Association do not have the
            right to attend to the meetings of the board of directors.
          </li>
          <li>
            Members of the Association who wish to speak at the board meetings
            MUST use the speak sign-in sheet and provide the following
            information on it:
          </li>
          <ol>
            <ul>Full Name</ul>
            <ul>Address</ul>
            <ul>Subject</ul>
          </ol>
          <li>
            Members who fail to provide the information set out by the three
            will not be called upon.
          </li>
          <li>
            Members will be given three minutes to state their comments or
            questions before the board; the board will do its best to provide
            answers in a timely manner.
          </li>
          <li>
            Members may not use derogatory or foul language while addressing the
            board.
          </li>
          <li>
            Members must remain respectful of the board and other members in
            attendance; failure to do so may result in the members(s) being
            asked to leave the meeting.
          </li>
        </ol>
      </div>
    </div>
  );
}
