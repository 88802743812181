import React from "react";

export default function June23() {
  const handleChange = (e) => {
    e.preventDefault(
      alert(
        "Copy & Paste Shadow Lakes Homeowner Association Content is not allowed"
      )
    );
  };
  return (
    <div
      className="container py-5"
      onCopy={handleChange}
      onCut={handleChange}
      onPaste={handleChange}
    >
      <h2 className="text-center py-3">Shadow Lakes Homeowners Association.</h2>
      <h3 className="text-center py-3">June 12 th 2023 minutes</h3>
      <p>
        The president Robert Wyllie commenced the June 2023 monthly meeting at
        19:30 sharp. Present were Robert Wyllie (president), Mike Jofre (vice
        president), Allan Griese (treasurer) and Vince Kiefer (Sgt. at Arms).
      </p>
      <p>
        The president called the previous months (May) minutes to be read. Allan
        Griese read the minutes in the secretary’s absence; the minutes were
        accepted by the board is accurate. The president then called for the
        treasury report and the treasurer reported as of 5-31-2023 the money
        market account contained $31,781.00 and the regular checking contained
        $753.47 for a combined total of $32,534.47. The treasurer also reported
        that after speaking with the bank officers at Truist the new interest
        rate for the money market account would be raised to 3.46% instead of
        the previous .01%. The treasurer also reported issuing a check to Terry
        Hunt for $55.00 for web services of which the board previously approved.
      </p>
      <p>
        The President called new business in the Sgt. at arms reported a deed
        violation concerning a failure to maintain the grass and an unauthorized
        pool; the Sgt. at arms stated he would contact the occupants. The next
        order of business was to change/add a board. Sharon Metzger was voted on
        to the board and will be taking the seat her husband previously held;
        the board voted unanimously to accept Sharon. The board also voted
        unanimously to provide funds for the cost of maintenance expenditures
        concerning entrance points into the neighborhood where residents do not
        do so already. The board also voted unanimously to suspend regular
        monthly meetings for the summer and plan the next monthly meeting to be
        held in September. However, the board if needed will convene for special
        meetings should the need arise.
      </p>
      <p>
        The vice president made a motion to close the meeting which was seconded
        by the Sgt. at arms and the board unanimously voted to convene the
        meeting. The meeting was concluded at 20:11.
      </p>
    </div>
  );
}
