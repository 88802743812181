import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Icon } from "@iconify/react";

export default function Hero() {
  return (
    <Container fluid className="bg-dark text-secondary">
      <Container fluid className="col-xl-10 col-xxl-8 px-4 py-5">
        <Row className="align-items-center g-lg-5 py-5">
          <Col className="text-center text-lg-start">
            <h1 className="display-4 fw-bold-1h-1 mb-3">
              Shadow Lakes{" "}
              <small className="d-flex">Homeowners Association</small>
            </h1>
            <p className="col-lg-10 fs-4">
              The Homeowner Association will convene for a meeting at the
              residence of Bob, located at 12641 Shadow Ridge Boulevard,
              commencing promptly at February 14 - 7:00 pm. <br />
              {/* <a
                href="https://goo.gl/maps/B2GBJmLBUofuL8Jo9"
                target={"_blank"}
                className="text-white-50"
                rel="noreferrer"
              >
                Veterans Park in the Pavilion (close to the swimming pool)
                <br />
                14333 Hicks Rd
                <br />
                Hudson, FL 34669
              </a>
              <br /> */}
            </p>
            <a
              href="https://nextdoor.com/g/6dwd30hvu/"
              target="_blank"
              className="px-2"
              rel="noreferrer"
            >
              <Icon
                icon="simple-icons:nextdoor"
                className="text-white"
                height={"100"}
              />
            </a>
            <br />
            <a className="text-white-50 px-1" href="/january-2024">
              January 2024 Minutes
            </a>

            {/* <a className="text-white-50 px-1" href="/april-2023">
              April 2023 Minutes
            </a>
            <a className="text-white-50 px-1" href="/may-2023">
              May 2023 Minutes (Annual Meeting)
            </a>
            <a className="text-white-50 px-1" href="/may-2023-regular">
              May 2023 Minutes (Regular Meeting)
            </a>{" "}
            <a className="text-white-50 px-1" href="/july-2023">
              July 2023 Minutes
            </a>
            <br />
            <a className="text-white-50 px-1" href="/june-2023">
              June 2023 Minutes
            </a>
            <a className="text-white-50 px-1" href="/october-2023">
              October 2023 Minutes
            </a>
            <a className="text-white-50 px-1" href="/november-2023">
              November 2023 Minutes
            </a>
            <a className="text-white-50 px-1" href="/december-2023">
              December 2023 Minutes
            </a>
             */}
          </Col>
        </Row>
        <Row>
          <p className="lead fw-bold" style={{ color: "#ed6622" }}>
            We extend our sincerest apologies for the slight delay in sharing
            the meeting minutes. The board members have been duly informed of a
            particular matter and are actively working towards its expeditious
            resolution.
          </p>
        </Row>
      </Container>
    </Container>
  );
}
