import React from "react";

export default function MayRegular() {
  const handleChange = (e) => {
    e.preventDefault(
      alert(
        "Copy & Paste Shadow Lakes Homeowner Association Content is not allowed"
      )
    );
  };
  return (
    <div
      className="container"
      onCopy={handleChange}
      onCut={handleChange}
      onPaste={handleChange}
    >
      <h2 className="text-center">Shadow Lakes Homeowners Association.</h2>
      <h3 className="text-center">May 11, 2023 minutes (Annual Meeting)</h3>
      <p>
        The president (Robert Wyllie) brings the meeting to order at 20:17. The
        president asks the secretary for any proxies, one is presented and
        inspected and accepted by the present and then returned to the
        secretary. <br />
        The president asks the secretary (Doug Foxx) to present the minutes from
        the April meeting. The president asks for acceptance of the minutes in
        the board accepts them. The president then asks the treasurer (Allan
        Griese) to present the treasury report. The treasurer reported as of
        4-49-2023 $33,913.30 were available; this was comprised of two accounts,
        the money market account and the regular business checking. The money
        market account contains $32,677.72 including $0.27 interest; and the
        regular checking contains $1,235.58. One deposit was made for $75.00 and
        five checks were written totaling $5,486.55. The president asked the
        board for acceptance of the report in the board accepted it.
      </p>
      <p>
        The president also asked about deed violations; Allan replied he had
        been working with a resident on Shadow Ridge Boulevard to clean up a
        long ongoing garage sale. It was also reported that this property would
        probably need at least 21 days to possibly 30 to resolve the issue since
        there was a death and multiple relatives and agents involved with the
        contents. Allan also reported the board is taking under advisement and
        working towards a resolution with another resident who has had multiple
        violation letters.
      </p>
      <p>
        The president took comments from the gallery. One resident asked the HOA
        attorney (Jack Delcamp) present at the meeting if the board was legally
        seated; the attorney advised that the board was in fact a legal entity.
        The treasurer and vice president (Mike Jofre) also conferred with the
        attorney that while residences of the HOA may speak with the attorney
        during the meeting; however, any questions to the attorney outside of
        the meeting must go through the board first. This is due to the fact
        that the attorney will charge the HOA for the services and the HOA must
        approve any monies to be spent beforehand. Residence not adhering and
        circumventing the board to gain access to the attorney may be liable for
        any attorney fees.
      </p>
      <p>
        The President receiving a motion from the floor from the vice president
        to conclude the meeting and being seconded by the Sgt. at Arms Vince
        Keifer closed the meeting at 20:47
      </p>
    </div>
  );
}
