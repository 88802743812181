import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function Footer() {
  return (
    <Container fluid className="bg-dark">
      <Container className="mt-5 p-5 bg-dark">
        <Row>
          <Col lg="5" xs="12">
            <h2 className=" text-secondary">
              Shadow Lakes{" "}
              <small className="d-block">Homeowners Association</small>
            </h2>
            <p className="pr-5 text-white-50">
              Shadow Lakes Homeowners Association is managed by volunteers from
              the homeowners of Shadow Lakes.
            </p>
          </Col>
          <Col lg="3" xs="12">
            <h4 className="mt-lg-0 mt-sm-3 text-secondary">Links</h4>
            <ul className="m-0 p-0">
              <li>
                <a href="/privacy-policy" className="text-white">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="/" className="text-white">
                  Cookies Policy
                </a>
              </li>
              <li>
                <a href="/" className="text-white">
                  Terms & Conditions
                </a>
              </li>
              <li>
                <a href="/" className="text-white">
                  Newsletter Policy
                </a>
              </li>
              <li>
                <a href="/" className="text-white">
                  California Residents
                </a>
              </li>
            </ul>
          </Col>
          <Col lg="4" xs="12" className="text-white-50">
            <h4 className="mt-lg-0 mt-sm-4 text-secondary">
              Payment - Mailing Address
            </h4>
            <p>
              14851 State Road 52 <br />
              Suite 107-158 <br />
              Hudson, FL 34669
            </p>
            <a href="/" className="mb-0 text-white">
              Temporary Disabled
            </a>
            <br />
            <a href="/contact" className="mb-0 text-white">
              Contact Us
            </a>
          </Col>
        </Row>
      </Container>
      <Row className="text-center text-secondary">
        <Col>
          <p>©Copyright 1982 - {new Date().getFullYear()}</p>
        </Col>
      </Row>
    </Container>
  );
}
