import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export default function NotFound() {
  return (
    <Container className='p-5' style={{ height: "80vh" }}>
      <Row>
        <Col>
          <h1>Um... Hehe...</h1>
          <p>This is a little embarassing!</p>
          <h2>The page isn't found. What are you looking for?</h2>
        </Col>
      </Row>
    </Container>
  );
}
