import React from "react";
import { Container } from "react-bootstrap";

export default function Deed() {
  return (
    <Container className="my-3">
      <Container>
        <h1 className="text-center display-4">
          Shadow Lakes Deed Restrictions
        </h1>
        <hr />
        <Container className="pt-4">
          <p>
            This document is a summary of the deed restriction that were voted
            on and agreed by a majority vote in 2013. The copy of the deed
            restrictions can be found and download at{" "}
            <a
              href="https://app.pascoclerk.com/appdot-public-sup-svcs-results-or-instr-detail.asp?mdqs=1&tbqs=2013141800"
              target={"_blank"}
              rel="noreferrer"
            >
              Pasco County Records
            </a>
            .
          </p>
          <p>
            These restrictions pertain to and are according to the plat thereof
            which is filed in Plat Book 20, page 27 through 29 of the Public
            Records of Pasco County, Florida. All lots in said subdivision
            are sold subject to the following restrictions and limitations which
            shall be covenants that shall run with the land and which are
            binding alike upon the heirs, personal representatives, assigns,
            successors in interest, whether in privity or not in, privity, or
            purchasers from any parcel Owner(s) or the HOA, or its successors or
            assigns, or by acceptance of a deed. All transferals agree to abide
            by, perform and carry out said restrictions, limitations and
            conditions as one of the express considerations of conveyance. These
            restrictions are to follow each successive transfer of Shadow Lakes,
            Unit 1 property, whether or not mentioned by future grantors and
            shall be in full force and effect for thirty (30) years as of the
            effective date of recording of these restrictions in the Public
            Records of Pasco County, Florida, pursuant to section 720.408, Fla.
            Stat.
          </p>
        </Container>
        <Container className="pb-4">
          <ol type="1">
            <li>
              <strong>RESIDENTIAL LOTS:</strong> All lots in said
              subdivision shall be known and described as residential
              lots. No structure shall be erected on any parcel ofthe some other
              than one (1) single private family dwelling with attached private
              garage of the same architecture and general design as the
              residences, both to be constructed of new materials. No lot shall
              be reduced or increased in size by any method whatsoever without
              prior written consent of the HOA. Common areas are not subject to
              this restriction.
            </li>
            <li>
              <strong>MINIMUM FLOOR AREA:</strong> All single family residences
              will have a minimum of one thousand (1,000) square feet for a
              one-story dwelling, and one thousand two hundred (1,200) square
              feet for a two-story dwelling, exclusive of screened porches,
              patios, breezeways or garages', outside wall dimensions may be
              used to determine square footage.
            </li>
            <li>
              <strong>GARAGE:</strong> All dwellings shall have at least a
              one-car garage. No carports shall be allowed.
            </li>
            <li>
              <strong>BUILDING SET-BACKS:</strong> No dwelling walls shall be
              erected nearer than twenty (20) feet to any front street lot line
              nor nearer than seven and one-half (7%) feet to any interior lo
              line.
            </li>
            <li>
              <strong>TYPE OF CONSTRUCTION:</strong> All dwellings on said lots
              must be constructed of new materials. The first floor walls of all
              dwellings shall be of frame, masonry, or masonry veneer
              construction. All concrete block houses and/or walls shall have a
              full stucco finish unless decorative block is used for decorative
              purposes. All roof coverings shall be cement tile, 240 pound
              asphalt shingle, or wood splits, except Florida rooms, porches, or
              other flat deck areas may be built up roofing or aluminum. Any
              deviation from these approved materials must receive the specific
              written approval of the HOA. The exterior colors of all homes must
              be compatible with the surrounding land and shall only be earth
              tone combinations.
            </li>
            <li>
              <strong>FENCES, WALLS, OTHER STRUCTURES AND OBSTRUCTIONS:</strong>{" "}
              Only wooden shadow-box fences, chain-link fences or white vinyl
              PVC may be constructed.
              <ol type="A">
                <li>No fence shall exceed six (6) feet in height.</li>
                <li>
                  No fence shall be constructed past the front wall line of any
                  dwelling.
                </li>
                <li>
                  No fence may be constructed upon any lot without the written
                  permission Of the HOA.
                </li>
              </ol>
            </li>
            <li>
              <strong>LANDSCAPING:</strong> All dwellings shall be constructed
              with concrete driveways, walks, solid sodded Front, side and rear
              yards.
            </li>
            <li>
              <strong>CLOTHES DRYING AREA:</strong> Portable rotary or wind-up
              disappearing type clothes lines shall be used; no permanent
              clothes lines shall be authorized.
            </li>
            <li>
              <strong>TEMPORARY STRUCTURES:</strong> Trailers, tents, shacks,
              barns, outbuildings, sheds, temporary buildings of any nature are
              expressly prohibited within this subdivision, and no
              temporary residence shall be permitted in unfinished residential
              buildings. No storage sheds shall be allowed on any lot within the
              confines of this subdivision. Temporary buildings or
              trailers may be used during daylight hours by contractors in
              connection with construction work in progress, but only upon
              written approval by the HOA.
            </li>
            <li>
              <strong>LIVESTOCK AND POULTRY:</strong> No animals, livestock,
              birds or poultry of any kind shall be raised, bred or kept on any
              lot, except that not more than a total of two (2) dogs, cats, or
              other household pets may be kept, provided that they are not kept,
              bred or maintained for any commercial purpose.
            </li>
            <li id="trailers-boats-rvs">
              <strong>PARKING OF VEHICLES:</strong> No vehicle shall be parked
              on any part of this property except on paved streets and paved
              driveways. No trailers or commercial vehicles, other than those
              present on business, may be parked in the subdivision.
              Boats, motor homes, commercial vehicles, campers and other
              recreational vehicles shall be parked inside garages and concealed
              from view. All parking shall be in accordance with state, county
              and city regulations and laws.
            </li>
            <li>
              <strong>SIGNS:</strong> No signs or promotional flags shall be
              displayed to the public view on any lot or building, except an
              owner of a lot may display a "For Sale" sign upon his lot not more
              than five (5) square feet or a real estate agent, for an owner,
              may display his or her real estate "for sale" sign on the lot.
            </li>
            <li>
              <strong>RAPID COMPLETION:</strong> The erection of any new
              building or repair of any building damaged by fire or otherwise
              shall be completed as rapidly as possible.
            </li>
            <li>
              <strong>GENERAL PROVISIONS:</strong>
              <ol type="A">
                <li>
                  <strong>ENFORCEMENT:</strong> The HOA and any person or
                  persons owning any real property which is subject to these
                  restrictions shall have the right to enforce, by any
                  proceeding at law or in equity, all restrictions, conditions,
                  covenants, reservations, liens and charges hereafter imposed
                  by the provisions of this Declaration. Failure by any owner to
                  enforce any covenant or restriction herein contained shall in
                  no event be deemed a waiver of the right to do so thereafter.
                </li>
                <li>
                  <strong>SEVERABILITY:</strong> Invalidation of any one of
                  these covenants or restrictions by judgment or court order
                  shall in no way affect any other provision which shall remain
                  in full force and effect.
                </li>
                <li>
                  <strong>AMENDMENT:</strong> The covenants and restrictions of
                  this Declaration shall run with and bind the land, for a term
                  of thirty (30) years from the date this Declaration is
                  recorded in accordance with section 720.408, Fla. stat. This
                  Declaration may be amended by a signed written instrument
                  approved by not less seventy-five percent (75%) of the lot
                  owners. Any amendment must be recorded in the Public Records
                  of Pasco County, Florida.
                </li>
              </ol>
            </li>
            <li>
              <strong>WATERWELLS:</strong> Shallow well type waterfalls shall be
              permitted upon a lot solely for the purpose of watering and caring
              for plants, trees, lawns, flowers and the like. Any such well
              shall be neatly concealed, protected, safe and shall be
              electrically operated.
            </li>
            <li>
              <strong>POWER AND TELEPHONE SERVICE:</strong> All electric power
              lines and conduits, telephone cables, cable television conduits
              and other utilities shall be run underground, if possible.
              Perpetual easements for the installation and maintenance of
              utilities and drainage facilities as shown on the plat or repeat,
              filed in the Public Records of Pasco County, Florida, are hereby
              reserved.
            </li>
            <li>
              <strong>SPECIFICATIONS:</strong> All construction shall equal or
              exceed construction requirements as outlined in the Southern
              Standard Building Code and local building codes, zoning ordinances
              and deed restrictions.
            </li>
            <li>
              <strong>SWIMMING POOLS:</strong> No swimming pool may be
              constructed which is not fully enclosed by an adequate screened
              enclosure or a minimum four (4) foot high locked fence. All
              swimming pools shall be below ground.
            </li>
            <li>
              <strong>GENERAL:</strong>
            </li>
            <ol type="A">
              <li>
                The ground grade or ground elevation of any portion of any lot
                may not be changed without the specific written consent of the
                HOA or his agent.
              </li>
              <li>
                No curb, drainage structure, water line, sewer line or portion
                of any sled shall be removed or altered for any purpose without
                the specific written consent of the HOA.
              </li>
              <li>
                Owner of respective lots shall be directly responsible
                financially to the HOA or proper authorities having jurisdiction
                for damage to the foregoing improvements resulting from the
                actions of employees or said owners or independent contractors
                furnishing labor or materials to or for said owners.
              </li>
              <li>
                No structure shall be erected, placed or permitted and no
                alterations shall be permitted on the property which shall in
                any way hinder the surface or subsurface drainage of the
                property.
              </li>
              <li>
                No noxious or offensive trade or commercial activity shall be
                carried on upon any lot, nor shall anything be done thereon
                which may be or may become an annoyance or nuisance to the
                neighborhood.
              </li>
              <li>
                No lot shall be used or maintained as a dumping ground for
                rubbish. trash, garbage, or other waste shall not be kept except
                in sanitary containers properly concealed from public view.
              </li>
              <li>
                Each lot, whether occupied or unoccupied, shall be maintained
                clean and free from refuse, debris, and unsightly growth such as
                tall pass and weeds or such as may be considered a fire hazard.
                All buildings, fences and walls shall be maintained in a good
                state of exterior repair. In the event that any owner shall
                fault, neglect or omit to maintain or keep clean any parcel or
                property in the manner therein provided, after having been
                noticed by the HOA to do so in writing addressed to such owner
                at his last known address, then the HOA for such Purpose may
                enter upon said premises for the purpose stated in said notice,
                and the expense of carrying out such purpose shall be charged to
                the owner of such lot and shall become a lien thereon
                collectable and enforceable in the manner provided by law.
              </li>
              <li>
                The terms "lot owners" and "parcel owners" as applied herein are
                deemed interchangeable.
              </li>
            </ol>
            <li>
              <strong>APPLICATION TO FUTURE ADDITIONS</strong>. These
              restrictions, limitations and covenants shall apply equally to all
              subsequent additions to Shadow Lakes, Unit 1, unless exceptions,
              additions, or modifications to these restrictions shall be filed
              contemporaneously with the filing of record of the plat for such
              subsequent additions.
            </li>
            <li>
              <strong>DISPUTE RESOLUTION</strong>. All parcel owners and the HOA
              shall apply the dispute resolution process, as required, pursuant
              to section 720.311, Fla. Stat.
            </li>
            <li>
              <strong>IDENTIFICATION OF AFFECTED PARCELS</strong>. Pursuant to
              section Fla. Stat., the attached Schedule "A" , same having been
              incorporated herein by reference, identifies each parcel owner
              that is to be subject to the governing documents by its legal
              description, and by the name of the parcel owner or the person in
              whose name the parcel owner is assessed on the last completed tax
              assessment in accordance with section 720.405(2), Fla. Stat.
            </li>
          </ol>
        </Container>
      </Container>
    </Container>
  );
}
