export const trimData = [
  {
    name: "White 52",
    lrv: "LRV:83 R:235 G:235 B:230",
    rgb: "235, 235, 230",
    url: "https://www.behr.com/consumer/ColorDetailView/52/",
  },
  {
    name: "Authentic Tan N290-2",
    lrv: "LRV:73 R:234 G:221 B:198",
    rgb: "234, 221, 198",
    url: "https://www.behr.com/consumer/ColorDetailView/N290-2/",
  },
  {
    name: "Twinkling Lights M280-1",
    lrv: "LRV:91 R:249 G:244 B:231",
    rgb: "249, 244, 231",
    url: "https://www.behr.com/consumer/ColorDetailView/M280-1/",
  },
  {
    name: "Astronomical N450-7",
    lrv: "LRV:7 R:71 G:75 B:74",
    rgb: "71, 75, 74",
    url: "https://www.behr.com/consumer/ColorDetailView/N450-7/",
    color: "255, 255, 255",
  },
  {
    name: "Frost 57",
    lrv: "LRV:87 R:239 G:240 B:236",
    rgb: "239, 240, 236",
    url: "https://www.behr.com/consumer/ColorDetailView/57/",
  },
  {
    name: "Sail Cloth N300-1",
    lrv: "LRV:78 R:236 G:229 B:215",
    rgb: "236, 229, 215",
    url: "https://www.behr.com/consumer/ColorDetailView/N300-1/",
  },
  {
    name: "Off White 73",
    lrv: "LRV:76 R:231 G:226 B:210",
    rgb: "231, 226, 210",
    url: "https://www.behr.com/consumer/ColorDetailView/73",
  },
  {
    name: "Arid Landscape N310-2",
    lrv: "LRV:67 R:220 G:214 B:198",
    rgb: "220, 214, 198",
    url: "https://www.behr.com/consumer/ColorDetailView/N310-2/",
  },
  {
    name: "Orion Gray N510-6",
    lrv: "LRV:9 R:83 G:85 B:88",
    rgb: "83, 85, 88",
    url: "https://www.behr.com/consumer/ColorDetailView/N510-6/",
    color: "255, 255, 255",
  },
  {
    name: "Sandstorm N310-3",
    lrv: "LRV:57 R:207 G:199 B:181",
    rgb: "207, 199, 181",
    url: "https://www.behr.com/consumer/ColorDetailView/N310-3/",
  },
  {
    name: "Adobe Sand N240-2",
    lrv: "LRV:65 R:225 G:208 B:190",
    rgb: "225, 208, 190",
    url: "https://www.behr.com/consumer/ColorDetailView/N240-2/",
  },
  {
    name: "Polo Tan N260-3",
    lrv: "LRV:46 R:202 G:176 B:149",
    rgb: "202, 176, 149",
    url: "https://www.behr.com/consumer/ColorDetailView/N260-3/",
  },
  {
    name: "Antique White 23",
    lrv: "LRV:73 R:232 G:221 B:202",
    rgb: "232, 221, 202",
    url: "https://www.behr.com/consumer/ColorDetailView/23/",
  },
  {
    name: "Desert Khaki N310-4",
    lrv: "LRV:42 R:183 G:172 B:151",
    rgb: "183, 172, 151",
    url: "https://www.behr.com/consumer/ColorDetailView/N310-4/",
  },
  {
    name: "Bake Sienna S220-6",
    lrv: "LRV:21 R:155 G:119 B:94",
    rgb: "155, 119, 94",
    url: "https://www.behr.com/consumer/ColorDetailView/S220-6/",
  },
  {
    name: "Casual Khaki N300-3",
    lrv: "LRV:57 R:211 G:197 B:175",
    rgb: "211, 197, 175",
    url: "https://www.behr.com/consumer/ColorDetailView/N300-3/",
  },
  {
    name: "Sand Drift N310-1",
    lrv: "LRV:75 R:229 G:224 B:211",
    rgb: "229, 224, 211",
    url: "https://www.behr.com/consumer/ColorDetailView/N310-1/",
  },
  {
    name: "ULTRA PURE WHITE",
    lrv: "LRV:94 R:248 G:248 B:243",
    rgb: "248, 248, 243",
    url: "https://www.behr.com/consumer/ColorDetailView/1850/",
  },
  {
    name: "Silver Polish BL-W13",
    lrv: "LRV:76 R:226 G:226 B:222",
    rgb: "226, 226, 222",
    url: "https://www.behr.com/consumer/ColorDetailView/BL-W13/",
  },
  {
    name: "Blackout N510-7",
    lrv: "LRV:5 R:65 G:63 B:62",
    rgb: "65, 63, 62",
    url: "https://www.behr.com/consumer/ColorDetailView/N510-7/",
    color: "255, 255, 255",
  },
  {
    name: "Swiss Coffee 12",
    lrv: "LRV:84 R:241 G:237 B:224",
    rgb: "241, 237, 224",
    url: "https://www.behr.com/consumer/ColorDetailView/12/",
  },
  {
    name: "Navajo White 22",
    lrv: "LRV:74 R:235 G:222 B:196",
    rgb: "235, 222, 196",
    url: "https://www.behr.com/consumer/ColorDetailView/22/",
  },
  {
    name: "Ashen Tan N220-2",
    lrv: "LRV:60 R:211 G:202 B:191",
    rgb: "211, 202, 191",
    url: "https://www.behr.com/consumer/ColorDetailView/N220-2/",
  },
  {
    name: "Woodcraft N200-5",
    lrv: "LRV:24 R:143 G:132 B:122",
    rgb: "143, 132, 122",
    url: "https://www.behr.com/consumer/ColorDetailView/N200-5/",
  },
  {
    name: "Toasty Gray N320-2",
    lrv: "LRV:61 R:209 G:204 B:192",
    rgb: "209, 204, 192",
    url: "https://www.behr.com/consumer/ColorDetailView/N320-2/",
  },
  {
    name: "Cottage White 13",
    lrv: "LRV:82 R:243 G:233 B:214",
    rgb: "243, 233, 214",
    url: "https://www.behr.com/consumer/ColorDetailView/13/",
  },
  {
    name: "Silver Marlin N360-2",
    lrv: "LRV:57 R:200 G:200 B:192",
    rgb: "200, 200, 192",
    url: "https://www.behr.com/consumer/ColorDetailView/N360-2",
  },
  {
    name: "Campfire Ash N320-1",
    lrv: "LRV:69 R:221 G:217 B:206",
    rgb: "221, 217, 206",
    url: "https://www.behr.com/consumer/ColorDetailView/N320-1/",
  },
  {
    name: "Sentimental Beige YL-W13",
    lrv: "LRV:69 R:224 G:216 B:197",
    rgb: "224, 216, 197",
    url: "https://www.behr.com/consumer/ColorDetailView/YL-W13/",
  },
  {
    name: "Still Gray N360-3",
    lrv: "LRV:40 R:171 G:169 B:160",
    rgb: "171, 169, 160",
    url: "https://www.behr.com/consumer/ColorDetailView/N360-3/",
  },
  {
    name: "Kindling N200-6",
    lrv: "LRV:17 R:122 G:112 B:104",
    rgb: "122, 112, 104",
    url: "https://www.behr.com/consumer/ColorDetailView/N200-6/",
    color: "255, 255, 255",
  },
  {
    name: "Binary Star N450-6",
    lrv: "LRV:13 R:97 G:103 B:103",
    rgb: "97, 103, 103",
    url: "https://www.behr.com/consumer/ColorDetailView/N450-6/",
    color: "255, 255, 255",
  },
];
