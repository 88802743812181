import React from "react";

export default function July23() {
  const handleChange = (e) => {
    e.preventDefault(
      alert(
        "Copy & Paste Shadow Lakes Homeowner Association Content is not allowed"
      )
    );
  };
  return (
    <div
      className="container"
      onCopy={handleChange}
      onCut={handleChange}
      onPaste={handleChange}
    >
      <h2 className="text-center">Shadow Lakes Homeowners Association.</h2>
      <h3 className="text-center">July 18, 2023 minutes</h3>
      <p>
        The July meeting was called to order by the president (Bob Wyllie) at
        19:02 present were Joseph Edwards, Vince Kiefer, Allan Griese, Doug Foxx
        and Bob Wyllie, Mike Jofre and Sharon Metzger were excused.
      </p>
      <p>
        The July meeting was called to order by the president (Bob Wyllie) at
        19:02 present were Joseph Edwards, Vince Kiefer, Allan Griese, Doug Foxx
        and Bob Wyllie, Mike Jofre and Sharon Metzger were excused.
      </p>
      <p>
        The July meeting was called to order by the president (Bob Wyllie) at
        19:02 present were Joseph Edwards, Vince Kiefer, Allan Griese, Doug Foxx
        and Bob Wyllie, Mike Jofre and Sharon Metzger were excused.
      </p>
      <p>
        The treasurer also opened a dialogue with the Sgt. at Arms who currently
        handles deed restriction enforcement. The Sgt. at Arms reported many of
        the prior concerns had been corrected in the board needed to take no
        further action. However, the treasurer inquired about two new violations
        in the Sgt. at Arms stated he would investigate and contact the
        homeowners; the board is waiting for his determination if any further
        action will need to be taken.
      </p>
      <p>
        The board also discussed a violation by a homeowner concerning the
        homeowner attempting start and assisted living facility business within
        the neighborhood. The board so far contacted Pasco County who stated the
        property appears to be in violation of HOA and county rules. The county
        is investigating the matter and assigned it an official case number. The
        board will take no further action until the county completes its
        investigation. The Sgt. at Arms made a motion to conclude the July
        meeting which was seconded by Doug Foxx. The board voted unanimously in
        the meeting was concluded at 19:52
      </p>
    </div>
  );
}
