import React from "react";
import { Accordion, Container, Table } from "react-bootstrap";

export default function FAQ() {
  return (
    <Container>
      <Container className="my-3 p-5 h-100">
        <h1 className="text-center display-4">Frequently Asked Questions</h1>
        <hr className="my-4" />
        <h4 className="pt-4">Information: Homeowners Association Assessment</h4>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              When are the Homeowners Association Assessment due?
            </Accordion.Header>
            <Accordion.Body>
              The treasurer sends out invoices by postal mail by April 15th.
              Therefore, the payments for the annual HOA are due at the
              beginning of May after June 1st and are considered late payments.
              If you have not received your invoice or are concerned about your
              payment, please use the <a href="/contact">contact form</a> or
              call <a href="tel:7278088624">(727) 808-8624</a>.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              How much are the Homeowners Association Assessment?
            </Accordion.Header>
            <Accordion.Body>
              The Homeowners Association Assessment costs $43 a year.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              How to pay the Annual Homeowners Association Assessment?
            </Accordion.Header>
            <Accordion.Body>
              At this moment, the Homeowners Association only accept check and
              money order. Unfortunately, there is no online payment available
              yet. Payment be made to Shadow Lakes HOA, and the address is
              below:
              <br />
              <br />
              <strong>
                Shadow Lakes HOA
                <br /> 14851 State Road 52
                <br /> Ste 107-158
                <br /> Hudson, FL 34669
              </strong>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <h4 className="pt-4">Homeowners Association</h4>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              Bought the house and was unaware that this neighborhood has a
              Homeowners Association.
            </Accordion.Header>
            <Accordion.Body>
              When you drive into any entrance of Shadow Lakes, there are signs
              that say "Deed Restriction" neighbor, which is the Homeowners
              Association. Whenever the house inside Shadow Lakes goes up for
              sale, the realtor has obtained a copy of the deed restriction and
              bylaws. When the realtor fills out an estoppel request, a copy of
              the deed restriction and bylaws are attached.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
      <Container className="my-3 p-5">
        <h4>Utility Companies</h4>
        <hr className="my-4" />
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Utility</th>
              <th>Company's Name</th>
              <th>Phone</th>
              <th>URL</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Electric</td>
              <td>Withlacoochee River Electric</td>
              <td>
                <a href="tel:7278689465">(727) 868-9465</a>
              </td>
              <td>
                <a
                  href="https://wrec.net/"
                  target="_blank"
                  without
                  rel="noopener noreferrer"
                >
                  Link
                </a>
              </td>
            </tr>
            <tr>
              <td>Water & Sewage</td>
              <td>Pasco County</td>
              <td>
                <a href="tel:7278478131">(727) 847-8131</a>
              </td>
              <td>
                <a
                  href="https://pascoeasypay.pascocountyfl.net/"
                  target="_blank"
                  without
                  rel="noopener noreferrer"
                >
                  Link
                </a>
              </td>
            </tr>
            <tr>
              <td>Landfill</td>
              <td>West Pasco Class III Landfill</td>
              <td>
                <a href="tel:7278613053">(727) 8613053</a>
              </td>
              <td>
                <a
                  href="https://www.pascocountyfl.net/188/West-Pasco-Facilities"
                  target="_blank"
                  without
                  rel="noopener noreferrer"
                >
                  Link
                </a>
              </td>
            </tr>
            <tr>
              <td>Solid Waste Collection</td>
              <td>Republic Services</td>
              <td>
                <a href="tel:7278682566">(727) 868-2566</a>
              </td>
              <td>
                <a
                  href="https://www.republicservices.com/"
                  target="_blank"
                  without
                  rel="noopener noreferrer"
                >
                  Link
                </a>
              </td>
            </tr>
            <tr>
              <td>Solid Waste Collection</td>
              <td>J.D. Parker & Sons</td>
              <td>
                <a href="tel:7278451024">(727) 845-1024</a>
              </td>
              <td>
                <a
                  href="https://jdparkerandsons.com/"
                  target="_blank"
                  without
                  rel="noopener noreferrer"
                >
                  Link
                </a>
              </td>
            </tr>
            <tr>
              <td>Internet, Phone, & TV Service Provider</td>
              <td>Frontier</td>
              <td>
                <a href="tel:8557988072">(855) 798-8072</a>
              </td>
              <td>
                <a
                  href="https://frontier.com/"
                  target="_blank"
                  without
                  rel="noopener noreferrer"
                >
                  Link
                </a>
              </td>
            </tr>
            <tr>
              <td>Internet, Phone, & TV Service Provider</td>
              <td>Spectrum</td>
              <td>
                <a href="tel:8779069121">(877) 906-9121</a>
              </td>
              <td>
                <a
                  href="https://www.spectrum.com"
                  target="_blank"
                  without
                  rel="noopener noreferrer"
                >
                  Link
                </a>
              </td>
            </tr>
          </tbody>
        </Table>
        <br />
      </Container>
    </Container>
  );
}
