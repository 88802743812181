import React from "react";

export default function April() {
  const handleChange = (e) => {
    e.preventDefault(
      alert(
        "Copy & Paste Shadow Lakes Homeowner Association Content is not allowed"
      )
    );
  };
  return (
    <div
      className="container"
      onCopy={handleChange}
      onCut={handleChange}
      onPaste={handleChange}
    >
      <h2 className="text-center">Shadow Lakes Homeowners Association.</h2>
      <h3 className="text-center">April 13, 2023 minutes</h3>
      <p>
        Pres. Wyllie called the meeting to order at 19:18 and stated that there
        had been special meetings of the board in March. The reason was to vote
        in new board members. The president asked Sec. Fox to read the minutes
        from the previous special meetings in the March board meeting.
      </p>
      <p>
        Secretary Fox read the minutes from the three meetings and they were
        accepted by the board. Pres. Wyllie then asked the treasurer for the
        treasury report which was presented by Allan Griese. The treasurer
        reported both accounts totaled $39,324.91 including interest. The
        treasurer also reported there had been four deposits and one check
        written.
      </p>
      <p>The board accepted the treasury report.</p>
      <p>
        The president asked for any other reports; Allan Griese stated there was
        no architectural report as no requests came to the board.
      </p>
      <p>New business</p>
      <p>The president then took questions from the community.</p>
      <p>
        Janice Ramsey inquired to the president if he had made an inquiry
        concerning residence receiving government aid for improvements to
        resident’s homes.
      </p>
      <p>
        The president replied the entity that he knew of in the past is now
        defunct and he would make further inquiries concerning this matter in
        the future in order to aid residents with fixed or low income. Pres.
        Wyllie (Bob) also informed Mrs. Ramsey (Janice) that the telephone
        turned over to the HOA in fact belonged to Mr. Ramsey. Mrs. Ramsey
        stated that the telephone turned over was billed to the debit card. Bob
        stated that he ordered the bank to destroy the debit card because a
        debit card does not have a check and balance option as the HOA requires
        two signatures and a board approval for all purchases. Mrs. Ramsey
        stated the HOA could dispose of, donate or etc. the phone she did not
        want it. Bob stated he would keep the phone in his briefcase. As a side
        note and after the meeting, Janice texted Allan Griese that after
        conferring with Mr. Ramsey they did in fact want to phone back. Janice
        stopped by Allan’s house to retrieve the phone Friday but missed him.
        Allan then sent his wife to the Ramsey’s house on Saturday morning but
        they did not answer the door. The phone is still in possession of the
        HOA and will be turned over to the Ramsey’s as soon as arrangements can
        be made.
      </p>
      <p>
        Janet also inquired if secretary Fox had paid late fees and interest on
        his past due HOA fees. Bob informed her that since we were not provided
        this information previously the board accepted his check of $43 and
        would forgive any late fees or interest.
      </p>
      <p>
        It was also brought to the attention by Gwen that she did not see the
        sign for the meeting and also about the annual meeting. The vice
        president informed her that the sheriff’s dept. had knocked down the
        sign at the Shadow Lakes/ Sugar Creek entrance in an unrelated matter
        and he put it up the next day. Allan Griese also informed her that a
        letter would be sent out soon informing of the date and time of the
        annual meeting and also stated at the April meeting it would be held in
        Veterans Park on 11 May 2023. The letter will also state this.
      </p>

      <p>
        A motion was made by Vince Sgt. at arms to end the meeting and seconded
        by Doug Fox the secretary.
      </p>
      <>Pres. Wyllie concluded the meeting at 19:42</>
    </div>
  );
}
