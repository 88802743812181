import React from "react";

export default function December23() {
  const handleChange = (e) => {
    e.preventDefault(
      alert(
        "Copy & Paste Shadow Lakes Homeowner Association Content is not allowed"
      )
    );
  };
  return (
    <div
      className="container"
      onCopy={handleChange}
      onCut={handleChange}
      onPaste={handleChange}
    >
      <h2 className="text-center">Shadow Lakes Homeowners Association.</h2>
      <h3 className="text-center">December 13, 2023 minutes</h3>
      <p>
        The president Robert Wyllie commenced the December 2023 monthly meeting
        at 19:13. Present were Robert Wyllie (president); Mike Jofre (vice
        president); Allan Griese (treasurer); Vince Kiefer (Sgt. at Arms); Doug
        Foxx (Secretary) and Joe Bowles (Director) was excused due to
        out-of-town business.
      </p>
      <p>
        The president called the previous months (Nov) minutes to be read. Doug
        Foxx read the minutes and the minutes were accepted by the board as
        accurate. The president then called for the treasury report and the
        treasurer reported as of Nov 30 st 2023 the money market account
        contained $32,651.14 and the regular checking contained $2493.97 for a
        combined total of $35,145.11.
      </p>
      <p>
        The President called for new business and updates. The Deed Enforcement
        (DEO) officer reported all prior violations were resolved except one.
      </p>
      <p>The president opened the floor.</p>
      <p>
        The treasurer made two motions; one to donate to a food shelf and donate
        toys for Toys for Tots. Doug Foxx seconded the motion and the president
        called for a vote. The motion passed unanimously.
      </p>
      <p>
        One of the residents from the neighborhood (Mark Weaver) had some issues
        and wanted to speak on the following subjects:
      </p>
      <p>
        He (Weaver) told the board he had been busy with work and holidays but
        reiterated he would continue to gather names to dissolve the HOA. The
        president reaffirmed to him that he needed 66.33% of the neighborhood
        residents to do so and if he accomplished this the board would take
        action to fulfill the neighborhoods wishes.
      </p>
      <p>The board set the next meeting date to be Monday Jan 8 th 2024</p>
      <p>
        The vice president made a motion to close the meeting which was seconded
        by the Sgt. at Arms and the board unanimously voted to convene the
        meeting. The meeting was concluded at 20:48.
      </p>
    </div>
  );
}
