export const paintData = [
  {
    name: "Weathered Moss N380-3",
    lrv: "LRV:49 R:186 G:187 B:179",
    rgb: "186, 187, 179",
    url: "https://www.behr.com/consumer/ColorDetailView/N380-3",
  },
  {
    name: "Jungle Camouflage N350-4",
    lrv: "LRV:38 R:170 G:167 B:148",
    rgb: "170, 167, 148",
    url: "https://www.behr.com/consumer/ColorDetailView/N350-4/",
  },
  {
    name: "Corn Stalk M290-3",
    lrv: "LRV:74 R:252 G:219 B:166",
    rgb: "252, 219, 166",
    url: "https://www.behr.com/consumer/ColorDetailView/M290-3/",
  },
  {
    name: "Milk Paint N330-1",
    lrv: "LRV:81 R:236 G:232 B:220",
    rgb: "236, 232, 220",
    url: "https://www.behr.com/consumer/ColorDetailView/N330-1/",
  },
  {
    name: "Liquid Mercury N510-5",
    lrv: "LRV:19 R:117 G:122 B:128",
    rgb: "117, 122, 128",
    url: "https://www.behr.com/consumer/ColorDetailView/N510-5/",
  },
  {
    name: "Not Available",
    lrv: "Not Available",
    rgb: "0, 0, 0",
    url: "#",
    color: "255, 255, 255",
  },
  {
    name: "Shiitake N220-4",
    lrv: "LRV:32 R:165 G:152 B:138",
    rgb: "165, 152, 138",
    url: "https://www.behr.com/consumer/ColorDetailView/N220-4/",
  },
  {
    name: "Cinnamon Tea S200-2",
    lrv: "LRV:56 R:222 G:192 B:173",
    rgb: "222, 192, 173",
    url: "https://www.behr.com/consumer/ColorDetailView/S200-2/",
  },
  {
    name: "Cascade Beige N240-1",
    lrv: "LRV:72 R:231 G:219 B:202",
    rgb: "231, 219, 202",
    url: "https://www.behr.com/consumer/ColorDetailView/N240-1/",
  },
  {
    name: "Incognito N370-5",
    lrv: "LRV:27 R:142 G:142 B:130",
    rgb: "142, 142, 130",
    url: "https://www.behr.com/consumer/ColorDetailView/N370-5/",
  },
  {
    name: "Pottery Wheel N250-3",
    lrv: "LRV:44 R:202 G:172 B:145",
    rgb: "202, 172, 145",
    url: "https://www.behr.com/consumer/ColorDetailView/N250-3/",
  },
  {
    name: "Coney Island N440-5",
    lrv: "LRV:20 R:109 G:126 B:125",
    rgb: "109, 126, 125",
    url: "https://www.behr.com/consumer/ColorDetailView/N440-5/",
  },
  {
    name: "Sustainable S350-4",
    lrv: "LRV:35 R:167 G:160 B:126",
    rgb: "167, 160, 126",
    url: "https://www.behr.com/consumer/ColorDetailView/S350-4/",
  },
  {
    name: "Pinecone Hill N410-6",
    lrv: "LRV:14 R:99 G:105 B:95",
    rgb: "99, 105, 95",
    url: "https://www.behr.com/consumer/ColorDetailView/N410-6/",
  },
  {
    name: "Gray Squirrel N320-5",
    lrv: "LRV:28 R:152 G:144 B:129",
    rgb: "152, 144, 129",
    url: "https://www.behr.com/consumer/ColorDetailView/N320-5/",
  },
  {
    name: "Nature's Gift N410-4",
    lrv: "LRV:35 R:153 G:163 B:153",
    rgb: "153, 163, 153",
    url: "https://www.behr.com/consumer/ColorDetailView/N410-4/",
  },
  {
    name: "Natural Twine S310-3",
    lrv: "LRV:57 R:219 G:195 B:155",
    rgb: "219, 195, 155",
    url: "https://www.behr.com/consumer/ColorDetailView/S310-3/",
  },
  {
    name: "Camping Tent N320-4",
    lrv: "LRV:43 R:182 G:175 B:160",
    rgb: "182, 175, 160",
    url: "https://www.behr.com/consumer/ColorDetailView/N320-4/",
  },
  {
    name: "Moonquake N450-4",
    lrv: "LRV:29 R:141 G:149 B:150",
    rgb: "141, 149, 150",
    url: "https://www.behr.com/consumer/ColorDetailView/N450-4/",
  },
  {
    name: "Polar Bear 75",
    lrv: "LRV:90 R:245 G:244 B:236",
    rgb: "245, 244, 236",
    url: "https://www.behr.com/consumer/ColorDetailView/75/",
  },
  {
    name: "Adirondack Blue N480-5",
    lrv: "LRV:23 R:116 G:133 B:143",
    rgb: "116, 133, 143",
    url: "https://www.behr.com/consumer/ColorDetailView/N480-5/",
  },
  {
    name: "River Road N270-5",
    lrv: "LRV:29 R:174 G:141 B:107",
    rgb: "174, 141, 107",
    url: "https://www.behr.com/consumer/ColorDetailView/N270-5/",
  },
  {
    name: "Livingston N330-5",
    lrv: "LRV:32 R:163 G:152 B:128",
    rgb: "163, 152, 128",
    url: "https://www.behr.com/consumer/ColorDetailView/N330-5/",
  },
  {
    name: "Open Canyon N300-4",
    lrv: "LRV:41 R:187 G:169 B:144",
    rgb: "187, 169, 144",
    url: "https://www.behr.com/consumer/ColorDetailView/N300-4/",
  },
  {
    name: "Weathered Fossil N310-5",
    lrv: "LRV:26 R:152 G:138 B:114",
    rgb: "152, 138, 114",
    url: "https://www.behr.com/consumer/ColorDetailView/N310-5/",
  },
  {
    name: "Midnight Blue N480-7",
    lrv: "LRV:9 R:76 G:86 B:94",
    rgb: "76, 86, 94",
    url: "https://www.behr.com/consumer/ColorDetailView/N480-7/",
    color: "255, 255, 255",
  },
  {
    name: "Mossy Oak N360-5",
    lrv: "LRV:22 R:132 G:129 B:120",
    rgb: "132, 129, 120",
    url: "https://www.behr.com/consumer/ColorDetailView/N360-5/",
  },
  {
    name: "Lunar Surface N460-3",
    lrv: "LRV:48 R:182 G:185 B:182",
    rgb: "182, 185, 182",
    url: "https://www.behr.com/consumer/ColorDetailView/N460-3/",
  },
  {
    name: "Wild Mustang N240-6",
    lrv: "LRV:18 R:143 G:113 B:92",
    rgb: "143, 113, 92",
    url: "https://www.behr.com/consumer/ColorDetailView/N240-6/",
  },
  {
    name: "Silver Ash GR-W11",
    lrv: "LRV:71 R:221 G:219 B:208",
    rgb: "221, 219, 208",
    url: "https://www.behr.com/consumer/ColorDetailView/GR-W11/",
  },
  {
    name: "Original White N290-1",
    lrv: "LRV:79 R:240 G:229 B:211",
    rgb: "240, 229, 211",
    url: "https://www.behr.com/consumer/ColorDetailView/N290-1/",
  },
  {
    name: "Light Year N370-3",
    lrv: "LRV:52 R:191 G:191 B:180",
    rgb: "191, 191, 180",
    url: "https://www.behr.com/consumer/ColorDetailView/N370-3",
  },
];
