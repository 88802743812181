import React from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";

export default function TopNavBar() {
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
        sticky="top"
      >
        <Container>
          <Navbar.Brand href="/" className="text-center">
            Shadow Lakes
            <small className="d-block text-center">
              Homeowners Association
            </small>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="m-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/hoa-meeting-rules">HOA Meeting Rules</Nav.Link>
              <NavDropdown title="About" id="collasible-nav-dropdown">
                <NavDropdown.Item href="/frequently-asked-questions">
                  Frequently Asked Questions
                </NavDropdown.Item>
                <NavDropdown.Item href="/approved-exterior-paint-colors">
                  Approved Paint Colors
                </NavDropdown.Item>
                <NavDropdown.Item href="/board-members">
                  Board Members
                </NavDropdown.Item>
                <NavDropdown.Item href="/deed-restrictions">
                  Deed Restrictions
                </NavDropdown.Item>
                <NavDropdown.Item href="/bylaws">Bylaws</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Forms" id="collasible-nav-dropdown">
                <NavDropdown.Item href="/contact">Contact</NavDropdown.Item>
                <NavDropdown.Item href="/complaint">
                  Complaint/Violation
                </NavDropdown.Item>
                <NavDropdown.Item href="/estoppel-request">
                  Estoppel Request
                </NavDropdown.Item>
                <NavDropdown.Item href="/architectural" disabled>
                  Architectural
                </NavDropdown.Item>
                <NavDropdown.Item href="/member-application">
                  Join Board/Fine Committee
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Nav>
              <Nav.Link href="tel:8136107109" className="text-center" disabled>
                Temporary Disabled
                <small className="d-block text-center">
                  Available 8:00 am - 8:30 pm daily
                </small>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
