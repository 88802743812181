import React from "react";

export default function Jan24() {
  const handleChange = (e) => {
    e.preventDefault(
      alert(
        "Copy & Paste Shadow Lakes Homeowner Association Content is not allowed"
      )
    );
  };
  return (
    <div
      className="container"
      onCopy={handleChange}
      onCut={handleChange}
      onPaste={handleChange}
    >
      <h2 className="text-center">Shadow Lakes Homeowners Association.</h2>
      <h3 className="text-center">July 8, 2024 minutes</h3>
      <p>
        The president Robert Wyllie commenced the January 2024 monthly meeting
        at 19:05. Present were Robert Wyllie (president); Mike Jofre (vice
        president); Allan Griese (treasurer); Vince Kiefer (Sgt. at Arms); and
        Joe Bowles (Director) via telephone. Doug Foxx (Secretary) was excused
        due to hospitalization.
      </p>
      <p>
        The president called the previous months (Dec) minutes to be read. Allan
        Griese read the minutes and the minutes were accepted by the board as
        accurate. The president then called for the treasury report and the
        treasurer reported as of Dec 29 th 2023 the money market account
        contained $30,104.40 and the regular checking contained $1254.89 for a
        combined total of $31,359.29. The treasurer reported of the total amount
        $53.26 were earned in interest. The treasurer also reported the HOA
        insurance had been paid in full and the HOA donated $577.45 in toys to
        Toys for Tots at the local pickup point and $531.68 in food was donated
        to Shady Hills Mission Chapels food shelf.
      </p>
      <p>
        The President called for new business and updates. The Deed Enforcement
        (DEO) officer reported four violations and the board will be addressing
        these issues first by letter to the owners.
      </p>
      <p>The president opened the floor.</p>
      <p>
        One of the residents from the neighborhood (Mark Weaver) had some issues
        and wanted to speak on the following subjects:
      </p>
      <p>
        He (Weaver) told the board that he would like to see new signage and
        some replaced and or updated. The president and vice president stated
        that signage would be looked into.
      </p>
      <p>
        The board due to scheduling issues left the next meeting date to be
        Monday Feb 13 th or 14 th 2024
      </p>
      <p>
        The vice president made a motion to close the meeting which was seconded
        by the Sgt. at Arms and the board unanimously voted to convene the
        meeting. The meeting was concluded at 20:29.
      </p>
    </div>
  );
}
