import React from "react";
import {
  Container,
  Row,
  FloatingLabel,
  Form,
  Col,
  Button,
} from "react-bootstrap";

export default function Contact() {
  return (
    <Container className='py-5 mb-5'>
      <h4>Contact Form</h4>
      <hr />
      <p>
        Feel free to contact the Shadow Lakes Homeowners Association. Please
        allow up to 72 hours for the association to reach back to you. If the
        association has not reached back to you after 72 hours (excluding the
        weekend and holiday), please feel free to re-submit a contact form or
        send an email at{" "}
        <a href='mailto:hello@shadowlakeshoa.info' className='text-black-50'>
          hello@shadowlakeshoa.info
        </a>
        .
      </p>
      <Form method='POST' name='contact'>
        <input type='hidden' name='form-name' value='contact' />
        <Form.Group>
          <Row>
            <Col>
              <FloatingLabel
                controlId='fullName'
                label='Full Name'
                className='mb-3'
              >
                <Form.Control
                  type='text'
                  placeholder='Full Name'
                  name='name'
                  required
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <FloatingLabel
                controlId='phoneNumber'
                label='Phone Number'
                className='mb-3'
              >
                <Form.Control
                  type='text'
                  placeholder='Phone Number'
                  name='phone'
                  required
                />
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel
                controlId='emailAddress'
                label='Email Address'
                className='mb-3'
              >
                <Form.Control
                  type='text'
                  placeholder='Email Address'
                  name='email'
                  required
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <FloatingLabel controlId='comments' label='Comments'>
                <Form.Control
                  as='textarea'
                  className='mb-3'
                  placeholder='Comments'
                  name='comments'
                  style={{ height: "200px" }}
                  required
                />
              </FloatingLabel>
            </Col>
          </Row>
        </Form.Group>
        <Button type='submit'>Submit</Button>
      </Form>
    </Container>
  );
}
