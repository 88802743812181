import React from "react";
import {
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
  Button,
} from "react-bootstrap";

export default function Complaint() {
  return (
    <Container className='pt-4'>
      <h4>Complaint Form</h4>
      <hr />
      <p>
        This is the Shadow Lakes Homeowners' Association Board of Directors'
        official process for addressing complaints. All complaints will be
        confidential and timely handled. The description of the violation must
        include the nature of and date of the alleged violation with an
        explanation of the factual basis of the complaint. (Who, What, Where,
        When, etc.).{" "}
        <strong>
          Every input boxes must be filled and validated, or the complaint form
          will be automatically rejected.
        </strong>
      </p>
      <Form method='POST' name='complaint'>
        <input type='hidden' name='form-name' value='complaint' />
        <Form.Group>
          <Row>
            <Col>
              <FloatingLabel
                className='mb-3'
                controlId='complainantName'
                label="Complainant's Name"
              >
                <Form.Control
                  type='text'
                  placeholder="Complainant's Name"
                  name='complainantName'
                  required
                />
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel
                className='mb-3'
                controlId='violatorName'
                label="Violator's Name"
              >
                <Form.Control
                  type='text'
                  placeholder="Violator's Name"
                  name='violatorName'
                  required
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <FloatingLabel
                className='mb-3'
                controlId='complainantAddress'
                label="Complainant's Address"
              >
                <Form.Control
                  type='text'
                  placeholder="Complainant's Address"
                  name='complainantAddress'
                  required
                />
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel
                className='mb-3'
                controlId='violatorAddress'
                label="Violator's Address"
              >
                <Form.Control
                  type='text'
                  placeholder="Violator's Address"
                  name='violatorAddress'
                  required
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <FloatingLabel
                className='mb-3'
                controlId='phoneNumber'
                label='Phone Number'
              >
                <Form.Control
                  type='text'
                  placeholder='Phone Number'
                  name='phoneNumber'
                  required
                />
                <Form.Text className='text-muted'>
                  Phone Number is not to be share with anyone.
                </Form.Text>
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel
                className='mb-3'
                controlId='emailAddress'
                label='Email Address'
              >
                <Form.Control
                  type='text'
                  placeholder='Email Address'
                  name='email'
                  required
                />
                <Form.Text className='text-muted'>
                  Email address is not to be share with anyone.
                </Form.Text>
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <FloatingLabel
                controlId='violation'
                label='Description of alleged violation'
                className='mb-3'
              >
                <Form.Control
                  as='textarea'
                  placeholder='Description of alleged violation'
                  name='message'
                  style={{ height: "200px" }}
                  required
                />
                <Form.Text className='text-muted'>
                  Description of the alleged violation must be related to Deed
                  Restrictions. Please review the{" "}
                  <a href='/deed-restrictions' className='text-black-50'>
                    Deed Restrictions
                  </a>{" "}
                  if unsure of the violation.
                </Form.Text>
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className='mb-3' controlId='fileMultiple'>
                <Form.Label>Multiple Photos Allowed</Form.Label>
                <Form.Control
                  type='file'
                  accept='.jpg, .png, .jpeg'
                  name='file'
                  multiple
                />
              </Form.Group>
            </Col>
          </Row>
        </Form.Group>
        <Button type='submit'>Submit</Button>
      </Form>
    </Container>
  );
}
