import React from "react";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  FloatingLabel,
} from "react-bootstrap";

export default function Estoppel() {
  return (
    <Container className='my-4'>
      <h4>Estoppel Request Form</h4>
      <hr />
      <p>
        This is the Shadow Lakes Homeowners Association's official process for
        the estoppel request form.{" "}
        <strong>
          All input boxes must be filled and validated, or the estoppel request
          form will be rejected.
        </strong>{" "}
        Please allow up to 48 hours during the business days to respond.
      </p>
      <Form method='POST' name='estoppel'>
        <input type='hidden' name='form-name' value='estoppel' />
        <Form.Group>
          <Row>
            <Col>
              <FloatingLabel
                controlId='requestorName'
                label="Requestor's Name"
                className='mb-3'
              >
                <Form.Control
                  type='text'
                  placeholder="Requestor's Name"
                  name='name'
                  required
                />
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel
                controlId='sellerName'
                label="Seller's Name"
                className='mb-3'
              >
                <Form.Control
                  type='text'
                  placeholder="Seller's Name"
                  name='sellerName'
                  required
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <FloatingLabel
                controlId='companyName'
                label="Company's Name"
                className='mb-3'
              >
                <Form.Control
                  type='text'
                  placeholder="Company's Name"
                  name='companyName'
                  required
                />
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel
                controlId='buyerName'
                label="Buyer's Name"
                className='mb-3'
              >
                <Form.Control
                  type='text'
                  placeholder="Buyer's Name"
                  name='buyerName'
                  required
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <FloatingLabel
                controlId='emailAddress'
                label='Email Address'
                className='mb-3'
              >
                <Form.Control
                  type='email'
                  placeholder='Email Address'
                  name='email'
                  required
                />
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel
                controlId='propertyAddress'
                label='Property Address'
                className='mb-3'
              >
                <Form.Control
                  type='text'
                  placeholder='Property Address'
                  name='address'
                  required
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <FloatingLabel
                controlId='faxNumber'
                label='Fax Number'
                className='mb-3'
              >
                <Form.Control
                  type='text'
                  placeholder='Fax Number'
                  name='faxNumber'
                />
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel
                controlId='zipcode'
                label='Hudson, Florida 34669'
                className='mb-3'
              >
                <Form.Control
                  type='text'
                  placeholder='Hudson, Florida 34669'
                  disabled
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId='formFileMultiple' className='mb-3'>
                <Form.Label>
                  <strong>
                    Estoppel form must be in PDF format. Bulk upload is allowed.
                  </strong>
                </Form.Label>
                <Form.Control type='file' accept='.pdf' name='file' multiple />
              </Form.Group>
            </Col>
          </Row>
        </Form.Group>
        <Button type='submit'>Submit</Button>
      </Form>
    </Container>
  );
}
