import React from "react";

export default function April() {
  const handleChange = (e) => {
    e.preventDefault(
      alert(
        "Copy & Paste Shadow Lakes Homeowner Association Content is not allowed"
      )
    );
  };
  return (
    <div
      className="container"
      onCopy={handleChange}
      onCut={handleChange}
      onPaste={handleChange}
    >
      <h2 className="text-center">Shadow Lakes Homeowners Association.</h2>
      <h3 className="text-center">November 13, 2023 minutes</h3>
      <p>
        The president Robert Wyllie commenced the October 2023 monthly meeting
        at 18:56. Present were Robert Wyllie (president); Mike Jofre (vice
        president); Allan Griese (treasurer); Vince Kiefer (Sgt. at Arms); Doug
        Foxx (Secretary) and Joe Edwards (Director). Joe Bowles (Director) was
        excused due to out-of-town business.
      </p>
      <p>
        The president called the previous months (Oct) minutes to be read. Doug
        Foxx read the minutes and the minutes were accepted by the board as
        accurate. The president then called for the treasury report and the
        treasurer reported as of Oct 31 st 2023 the money market account
        contained $32,596.73 and the regular checking contained $2708.19 for a
        combined total of $35,304.92. This amount includes $645 from estoppel
        fees and $95.64 of interest paid. The treasurer also reaffirmed that
        last year’s budget and expenditures would be made available on the
        Shadow Lakes website in order to save mailing expenses and when the
        webmaster could assure they would remain secure.
      </p>
      <p>
        The President called for new business and updates. The Deed Enforcement
        (DEO) officer reported four (4) violations, two which have been
        resolved. One of the matters is currently being discussed within the
        board; the other matter, the DEO presented the violation letter for
        board approval. The approved letter will be dispatched via certified
        mail since this is the second notice.
      </p>
      <p>The president opened the floor.</p>
      <p>
        One of the residents from the neighborhood (Mark Weaver) had some issues
        and wanted to speak on the following subjects: He (Weaver) came just
        before the meeting commenced and was concerned if enough board members
        would be present. The president replied he was
      </p>
      <p>
        confident that at least enough would be present to obtain a quorum (four
        members) . Weaver replied in the event there was to be an issue having
        enough present that the board should consider adding board members as
        “proxy members” that could be called upon to fill any gaps. The
        president replied that when the board was revitalized after the prior
        board all resigned that the community again voted only to have seven
        members. By the time the meeting commenced at 18:56 six of the seven
        members were present (one had an excused absence).
      </p>
      <p>
        Mr. Weaver also stated he wants the meeting dates 30 days prior. The
        president stated that according to the by-laws are to be held on the 11
        th of each month. However, dates may have to be shifted according to
        holidays and member schedules.
      </p>
      <p>
        Mr. Weaver also wants to spend more HOA funds to speed up projects.
        Again, the president replied that the HOA fully intends to complete its
        projects (signage, lighting, etc.) in the most economical manner in
        order to keep dues as low as possible. Mr. Weaver again reintegrated he
        felt it was a mistake the board decided not to collect dues for the
        fiscal year of 2023. He (Weaver) also commented that the HOA promised to
        get some kind of storage area for HOA records so that they would not be
        lost as in the case of prior leadership. The records are currently
        stored with the president and the treasurer. All of the current records
        since the current board took office can be inspected by residents by
        appointment and are safe. The president further commented that the few
        records since the current board generated do not justify renting storage
        space due to high storage space costs simply to store at this time
        possibly two banker sized boxes. These costs if implemented would not
        show fiscal responsibility to the neighborhood.
      </p>
      <p>
        Mr. Weaver also inquired why the HOA board had not performed an audit as
        promised concerning missing funds. The president, vice president and
        treasurer all commented that they had consulted with the HOA attorney,
        the bank and an accounting firm. The board had examined the books and
        found less than $100 missing over the course of several years prior to
        the current board taking control. However, the board unanimously decided
        with legal advise it could not justify hiring an outside firm to perform
        a professional third-party audit costing over $1000.00 to possibly
        recover less than $100. The current board decided it was more realistic
        to govern itself most accurately and annually present the records to the
        attorney for verification. Mr. Weaver also wanted the HOA minutes to be
        more detailed and he expressed his discontent of board members answers
        as excuses concerning the handling HOA business. The president replied
        issues in the neighborhood were being handled as timely as possible
        within member’s schedules and not his personal time-line. Mr. Weaver
        also commented that if the board would continue to make excuses and did
        not have interest in the neighborhood they should quit or dissolve the
        HOA; and that he would go door to door to generate interest to do so.
        The president replied that the board was interested and indeed was
        serving the neighborhood and did not have a direct interest one way or
        the other for dissolution. And that if in fact the neighborhood did no
        longer want an HOA anymore the board would not stand in the way. The
        president informed him (Weaver) that he would need 66.33% to enact such,
        and if this is what the neighborhood indeed wanted it would be
        introduced to the HOA attorney for action.
      </p>
      <p>
        The vice president made a motion to close the meeting which was seconded
        by the Sgt. at Arms and the board unanimously voted to convene the
        meeting. The meeting was concluded at 21:13.
      </p>
    </div>
  );
}
