import React from "react";
import Features from "./Components/Pages/Features";
import Hero from "./Components/Pages/Hero";

export default function App() {
  return (
    <>
      <Hero />
      <Features />
    </>
  );
}
