export const accentData = [
  {
    name: "English Custard M290-5",
    lrv: "LRV:51 R:226 G:182 B:108",
    rgb: "226, 182, 108",
    url: "https://www.behr.com/consumer/ColorDetailView/M290-5/",
  },
  {
    name: "Summerwood S290-4",
    lrv: "LRV:48 R:212 G:178 B:139",
    rgb: "212, 178, 139",
    url: "https://www.behr.com/consumer/ColorDetailView/S290-4/",
  },
  {
    name: "Cliffside Park S390-6",
    lrv: "LRV:20 R:111 G:129 B:101",
    rgb: "111, 129, 101",
    url: "https://www.behr.com/consumer/ColorDetailView/S390-6",
  },
  {
    name: "Not Available",
    lrv: "Not Available",
    rgb: "0, 0, 0",
    url: "#",
    color: "255, 255, 255",
  },
  {
    name: "Not Available",
    lrv: "Not Available",
    rgb: "0, 0, 0",
    url: "#",
    color: "255, 255, 255",
  },
  {
    name: "Fresh Brew M330-5",
    lrv: "LRV:40 R:184 G:170 B:125",
    rgb: "184, 170, 125",
    url: "https://www.behr.com/consumer/ColorDetailView/M330-5/",
  },
  {
    name: "Washed Olive S350-3",
    lrv: "LRV:52 R:197 G:192 B:163",
    rgb: "197, 192, 163",
    url: "https://www.behr.com/consumer/ColorDetailView/S350-3/",
  },
  {
    name: "Night Flight S520-7",
    lrv: "LRV:7 R:67 G:77 B:92",
    rgb: "67, 77, 92",
    url: "https://www.behr.com/consumer/ColorDetailView/S520-7/",
    color: "255, 255, 255",
  },
  {
    name: "Harmonious Gold M300-3",
    lrv: "LRV:65 R:234 G:207 B:163",
    rgb: "234, 207, 163",
    url: "https://www.behr.com/consumer/ColorDetailView/M300-3/",
  },
  {
    name: "Havana Coffee N210-7",
    lrv: "LRV:7 R:85 G:73 B:65",
    rgb: "85, 73, 65",
    url: "https://www.behr.com/consumer/ColorDetailView/N210-7/",
    color: "255, 255, 255",
  },
  {
    name: "Whiskey Barrel N230-6",
    lrv: "LRV:17 R:133 G:112 B:95",
    rgb: "133, 112, 95",
    url: "https://www.behr.com/consumer/ColorDetailView/N230-6/",
  },
  {
    name: "Cavalry Brown N220-7",
    lrv: "LRV:10 R:101 G:86 B:73",
    rgb: "101, 86, 73",
    url: "https://www.behr.com/consumer/ColorDetailView/N220-7/",
  },
  {
    name: "Grand Plum N120-7",
    lrv: "LRV:11 R:108 G:86 B:87",
    rgb: "108, 86, 87",
    url: "https://www.behr.com/consumer/ColorDetailView/N120-7/",
  },
  {
    name: "Perfect Penny S180-6",
    lrv: "LRV:18 R:160 G:106 B:86",
    rgb: "160, 106, 86",
    url: "https://www.behr.com/consumer/ColorDetailView/S180-6/",
  },
  {
    name: "Cherry Cola S130-7",
    lrv: "LRV:8 R:116 G:69 B:67",
    rgb: "116, 69, 67",
    url: "https://www.behr.com/consumer/ColorDetailView/S130-7/",
  },
  {
    name: "Spiced Berry S150-6",
    lrv: "LRV:12 R:138 G:81 B:72",
    rgb: "138, 81, 72",
    url: "https://www.behr.com/consumer/ColorDetailView/S150-6/",
  },
  {
    name: "Whale Gray N470-6",
    lrv: "LRV:13 R:89 G:103 B:107",
    rgb: "89, 103, 107",
    url: "https://www.behr.com/consumer/ColorDetailView/N470-6/",
  },
  {
    name: "Ink Black N490-7",
    lrv: "LRV:7 R:69 G:73 B:77",
    rgb: "69, 73, 77",
    url: "https://www.behr.com/consumer/ColorDetailView/N490-7/",
    color: "255, 255, 255",
  },
  {
    name: "Tornado Season S450-6",
    lrv: "LRV:15 R:77 G:113 B:121",
    rgb: "77, 113, 121",
    url: "https://www.behr.com/consumer/ColorDetailView/S450-6/",
  },
  {
    name: "No More Drama P140-7",
    lrv: "LRV:12 R:163 G:63 B:64",
    rgb: "163, 63, 64",
    url: "https://www.behr.com/consumer/ColorDetailView/P140-7/",
  },
  {
    name: "Sugar Beet M130-7",
    lrv: "LRV:9 R:131 G:66 B:83",
    rgb: "131, 66, 83",
    url: "https://www.behr.com/consumer/ColorDetailView/M130-7/",
  },
  {
    name: "Aubergine N100-7",
    lrv: "LRV:8 R:88 G:75 B:77",
    rgb: "88, 75, 77",
    url: "https://www.behr.com/consumer/ColorDetailView/N100-7/",
  },
  {
    name: "Equestrian Green S410-7",
    lrv: "LRV:12 R:84 G:101 B:79",
    rgb: "84, 101, 79",
    url: "https://www.behr.com/consumer/ColorDetailView/S410-7/",
  },
  {
    name: "Hematite N460-6",
    lrv: "LRV:12 R:95 G:97 B:95",
    rgb: "95, 97, 95",
    url: "https://www.behr.com/consumer/ColorDetailView/N460-6/",
  },
  {
    name: "Not Available",
    lrv: "Not Available",
    rgb: "0, 0, 0",
    url: "#",
    color: "255, 255, 255",
  },
  {
    name: "Plantain Chips M290-6",
    lrv: "LRV:42 R:214 G:165 B:80",
    rgb: "214, 165, 80",
    url: "https://www.behr.com/consumer/ColorDetailView/M290-6/",
  },
  {
    name: "Soft Boiled P270-6",
    lrv: "LRV:55 R:255 G:183 B:55",
    rgb: "255, 183, 55",
    url: "https://www.behr.com/consumer/ColorDetailView/P270-6/",
  },
  {
    name: "Rio Rust S190-6",
    lrv: "LRV:17 R:146 G: 105 B: 86",
    rgb: "146, 105, 86",
    url: "https://www.behr.com/consumer/ColorDetailView/S190-6/",
  },
  {
    name: "Not Available",
    lrv: "Not Available",
    rgb: "0, 0, 0",
    url: "#",
    color: "255, 255, 255",
  },
  {
    name: "Arrowhead N320-6",
    lrv: "LRV:18 R:125 G:115 B:99",
    rgb: "125, 115, 99",
    url: "https://www.behr.com/consumer/ColorDetailView/N320-6/",
  },
  {
    name: "Not Available",
    lrv: "Not Available",
    rgb: "0, 0, 0",
    url: "#",
    color: "255, 255, 255",
  },
  {
    name: "Not Available",
    lrv: "Not Available",
    rgb: "0, 0, 0",
    url: "#",
    color: "255, 255, 255",
  },
];
