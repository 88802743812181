import React from "react";

export default function April() {
  const handleChange = (e) => {
    e.preventDefault(
      alert(
        "Copy & Paste Shadow Lakes Homeowner Association Content is not allowed"
      )
    );
  };
  return (
    <div
      className="container"
      onCopy={handleChange}
      onCut={handleChange}
      onPaste={handleChange}
    >
      <h2 className="text-center">Shadow Lakes Homeowners Association.</h2>
      <h3 className="text-center">October 11, 2023 minutes</h3>
      <p>
        The president Robert Wyllie commenced the October 2023 monthly meeting
        at 19:04. Present were Robert Wyllie (president), Mike Jofre (vice
        president), Allan Griese (treasurer) and Vince Kiefer (Sgt. at Arms).
      </p>
      <p>
        The president called the previous months (July) minutes to be read.
        Allan Griese read the minutes in the secretary’s absence; the minutes
        were accepted by the board as accurate. The president then called for
        the treasury report and the treasurer reported as of Sep 29 th 2023 the
        money market account contained $32,501.09 and the regular checking
        contained $2063.19 for a combined total of $34,564.28. The treasurer
        also reported that after reviewing the accounts last year’s budget and
        expenditures would be made available on the Shadow Lakes website in
        order to save mailing expenses.
      </p>
      <p>
        The President called for new business. Allan Griese nominated Josef
        Bowles as a new board member; Vince Keifer seconded the motion and the
        board voted unanimously to accept the new member. Allan Griese also made
        motion to add deed restrictions as part of the Sgt. at Arms (Vince
        Keifer) duties. The Vice President seconded the motion and the board
        voted unanimously to accept the motion. The board further discussed
        several problems concerning deed violations and how to proceed. The
        board also set the date of the fall garage sale to be set on October 28
        th 2023.
      </p>
      <p>
        The vice president made a motion to close the meeting which was seconded
        by the Sgt. at Arms and the board unanimously voted to convene the
        meeting. The meeting was concluded at 19:48.
      </p>
    </div>
  );
}
