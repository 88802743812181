import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const members = [
  { title: "President", name: "Robert Wyllie" },
  { title: "Vice-President", name: "Mike Jofre" },
  { title: "Secretary", name: "Douglas Fox" },
  { title: "Treasurer", name: "Allan Griese" },
  { title: "Director", name: "Vincent Kerfer" },
  { title: "Director", name: "Joseph Edwards" },
  { title: "Director", name: "Scott Metzcher" },
];

export default function Board() {
  return (
    <Container className="py-3">
      <h1 className="text-center display-4">Board Members</h1>
      <hr />
      <p>
        The Shadow Lakes Homeowners Association (SLHOA) works on behalf of the
        residents of the Shadow Lakes subdivision to protect, maintain and
        improve the common areas, privileges, and interests of its residents.
      </p>
      <p>
        The SLHOA is solely comprised of volunteers that give their time and
        talents for the benefit of the neighborhood. The SLHOA is always in need
        of enthusiastic, caring, motivated volunteers. If you would like to be
        part of a group of people that value the importance of the quality of
        life in the neighborhood where you live, please contact any Board
        members or <a href="/contact">contact form</a> to get involved.
      </p>
      <Row>
        {members.map((res) => (
          <Col md={4} className="my-4 text-center">
            <h4>{res.title}</h4>
            <h5>{res.name}</h5>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
