import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { paintData } from "../data/paintData";
import { trimData } from "../data/trimData";
import { accentData } from "../data/accentData";

export default class Paint extends Component {
  render() {
    return (
      <Container className="my-5">
        <div>
          <p className="text-center display-4">Approved Exterior Paint Color</p>
          <hr className="my-4" />
          <p className="text-center lead">
            <strong>
              No Reds, Dark Blue or Soft Boiled P270-6 for base colors.
            </strong>
          </p>
          <p className="text-center">
            Colors may appear different based on your electronic resolution and
            settings. Color codes are the same as retrieved from Behr’s website.
          </p>
          <br />
        </div>
        <Row className="text-center" sm={3}>
          <Col>
            {paintData.map((data) => {
              return (
                <div
                  className="pt-5 pb-5 mt-3"
                  style={{
                    color: `rgb(${data.color})`,
                    background: `rgb(${data.rgb})`,
                  }}
                >
                  <p>Paint</p>
                  <a href={data.url} target="_blank" rel="noopener noreferrer">
                    {data.name}
                  </a>
                  <p>{data.lrv}</p>
                </div>
              );
            })}
          </Col>
          <Col>
            {trimData.map((data) => {
              return (
                <div
                  className="pt-5 pb-5 mt-3"
                  style={{
                    color: `rgb(${data.color})`,
                    background: `rgb(${data.rgb})`,
                  }}
                >
                  <p>Trim</p>
                  <a href={data.url} target="_blank" rel="noopener noreferrer">
                    {data.name}
                  </a>
                  <p>{data.lrv}</p>
                </div>
              );
            })}
          </Col>
          <Col>
            {accentData.map((data) => {
              return (
                <div
                  className="pt-5 pb-5 mt-3"
                  style={{
                    color: `rgb(${data.color})`,
                    background: `rgb(${data.rgb})`,
                  }}
                >
                  <p>Accent</p>
                  <a href={data.url} target="_blank" rel="noopener noreferrer">
                    {data.name}
                  </a>
                  <p>{data.lrv}</p>
                </div>
              );
            })}
          </Col>
        </Row>
      </Container>
    );
  }
}
