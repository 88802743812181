import React from "react";

export default function May() {
  const handleChange = (e) => {
    e.preventDefault(
      alert(
        "Copy & Paste Shadow Lakes Homeowner Association Content is not allowed"
      )
    );
  };
  return (
    <div
      className="container"
      onCopy={handleChange}
      onCut={handleChange}
      onPaste={handleChange}
    >
      <h2 className="text-center">Shadow Lakes Homeowners Association.</h2>
      <h3 className="text-center">May 11, 2023 minutes (Annual Meeting)</h3>
      <p>
        The president (Bob Wyllie) opened the annual meeting at 19:30 sharp.
        <br />
        The president asked for any proxies from the secretary, the secretary
        provided one proxy to the president for inspection; the president
        accepted the proxy use the member. The president returned the proxy to
        the secretary.
      </p>
      <p>
        The president then read his prepared statement giving a brief history
        statement concerning the board’s history since June of 2018. His
        statement also included events since the March 2023 board meeting. He
        concluded his statement with what the board has accomplished since March
        and what the board intends to accomplish for the fiscal 2023/2024 year.
      </p>
      <p>
        The president asked the secretary (Allan Griese) to present the minutes
        and the secretary read the minutes and they were accepted by the board.
        The president then asked the treasurer for his report and the treasurer
        presented the annual budget the annual budget and the year-end
        statement. The treasurer reported a $96.11 difference between
        expenditures and the bank statement. The treasurer vowed to audit the
        available records and try to find the difference. He also stated the
        statement of expenditures and the budget would be mailed to residents as
        soon as possible. He then returned the floor back to the present. The
        president asked the board to accept the treasury report and the board
        accepted the treasury report.
      </p>
      <p>
        The president then took questions from the neighborhood audience. The
        main concern brought up by Mark Weaver and others revolved around poor
        record-keeping the past and doing future periodic audits. Mark asked if
        the board was willing and for them to vote to do so at least quarterly
        auditing the finances. The board voted and agreed unanimously. It was
        also brought up for HOA records to be stored at a third-party secure
        location. The board in the future will explore this option since at the
        moment there are few records.
      </p>
      <p>
        Vince Keifer made a motion at 20:15 include the annual meeting, seconded
        by Mike Jofre. The president closed the 2023 annual meeting at 20:16
      </p>
      
    </div>
  );
}
