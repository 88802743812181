import React from "react";
import {
  Col,
  Container,
  Form,
  Row,
  Button,
  FloatingLabel,
} from "react-bootstrap";

export default function Join() {
  return (
    <Container className='pt-4'>
      <h4>Application</h4>
      <hr />
      <p>
        Application to join Shadow Lakes Homeowners Association Board Member or
        Fine Committee. Please fill out the application form fully and allow the
        board members to review the application within three business days or
        longer.
      </p>
      <Form method='POST' name='join'>
        <input type='hidden' name='form-name' value='join' />
        <Form.Group>
          <Row>
            <Col>
              <FloatingLabel
                className='mb-3'
                controlId='fName'
                label='Full Name'
              >
                <Form.Control type='text' placeholder='Full Name' name="fullName" required />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <FloatingLabel
                className='mb-3'
                controlId='address'
                label='Address'
              >
                <Form.Control type='text' placeholder='Address' name="address" required />
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel
                className='mb-3'
                controlId='zipcode'
                label='Hudson, FL 34669'
              >
                <Form.Control
                  type='text'
                  placeholder='Hudson, FL 34669'
                  disabled
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <FloatingLabel
                className='mb-3'
                controlId='emailAddress'
                label='Email Address'
              >
                <Form.Control
                  type='text'
                  placeholder='Email Address'
                  name="email"
                  required
                />
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel
                className='mb-3'
                controlId='phoneNumber'
                label='Phone Number'
              >
                <Form.Control type='text' placeholder='Phone Number' name="phone" required />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <FloatingLabel
                controlId='reason'
                label='Explanation of the reasoning to join'
                className='mb-3'
              >
                <Form.Control
                  as='textarea'
                  placeholder='Explanation of the reasoning to join'
                  name="message"
                  style={{ height: "200px" }}
                  required
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId='violation'>
                <Form.Select className='mb-3' name='violation[]' required>
                  <option>Previously Violations?</option>
                  <option value={"Yes"}>Yes</option>
                  <option value={"No"}>No</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId='position'>
                <Form.Select className='mb-3' name='role[]' required>
                  <option>Position Applying</option>
                  <option value={"President"}>President</option>
                  <option value={"Vice-President"}>Vice-President</option>
                  <option value={"Deed Enforcement"}>Deed Enforcement</option>
                  <option value={"Treasurer"}>Treasurer</option>
                  <option value={"Architectural"}>Architectural</option>
                  <option value={"Securtary"}>Securtary</option>
                  <option value={"Board Member"}>Board Member</option>
                  <option value={"Fine Committee"}>Fine Committee</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Form.Group>
        <Button type='submit'>Submit</Button>
      </Form>
    </Container>
  );
}
