import React, { useState } from "react";
import {
  Container,
  Form,
  Row,
  Col,
  Button,
  FloatingLabel,
  InputGroup,
  FormControl,
} from "react-bootstrap";

export default function Architectural() {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const [checked, setChecked] = useState(false);
  const [text, setText] = useState("");
  return (
    <Container className="pt-4">
      <h4>Architectural Submission/Application Form</h4>
      <hr />
      <p>
        The architectural application form must be filled out entirely or will
        automatically be rejected. The forms can be{" "}
        <a
          href="./files/Shadow-Lakes-Architectural-Application-Form.pdf"
          className="text-black-50"
        >
          download
        </a>{" "}
        and filled out for hand-delivered in person to any board member at the
        homeowners association board meeting.{" "}
        <strong>
          Do not hand-delivered the forms to the board member’s resident
        </strong>
        . The forms can also be mailed at Shadow Lakes Homeowners Association
        14851 State Road 52 Suite 107-158 Hudson, FL 34669. Please allow up to
        72 hours for one of the board members to respond to the status of the
        form.
      </p>

      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col>
            <FloatingLabel
              controlId="floatingInput"
              label="Owner (Applicant)"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Owner (Applicant)"
                required
              />
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel
              controlId="floatingInput"
              label="Account #"
              className="mb-3"
            >
              <Form.Control type="text" placeholder="Account #" />
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col>
            <FloatingLabel
              controlId="floatingInput"
              label="Propety Address"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Propety Address"
                required
              />
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel
              controlId="floatingInput"
              label="Zip code"
              className="mb-3"
            >
              <Form.Control type="text" placeholder="Zip code" required />
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col>
            <FloatingLabel
              controlId="floatingInput"
              label="Phase"
              className="mb-3"
            >
              <Form.Control type="text" placeholder="Phase" />
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel
              controlId="floatingInput"
              label="Lot"
              className="mb-3"
            >
              <Form.Control type="text" placeholder="Lot" />
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel
              controlId="floatingInput"
              label="Block"
              className="mb-3"
            >
              <Form.Control type="text" placeholder="Block" />
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel
              controlId="floatingInput"
              label="Sub"
              className="mb-3"
            >
              <Form.Control type="text" placeholder="Sub" required />
            </FloatingLabel>
          </Col>
        </Row>
        <h4>Contractor Information</h4>
        <hr />
        <Row>
          <Col>
            <FloatingLabel
              controlId="floatingInput"
              label="Contractor Name"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Contractor Name"
                required
              />
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col>
            <FloatingLabel
              controlId="floatingInput"
              label="Contractor Phone Number"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Contractor Phone Number"
                required
              />
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel
              controlId="floatingInput"
              label="Contractor Email"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Contractor Email"
                required
              />
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col>
            <FloatingLabel
              controlId="floatingInput"
              label="Project Start Date"
              className="mb-3"
            >
              <Form.Control
                type="date"
                placeholder="Project Start Date"
                required
              />
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel
              controlId="floatingInput"
              label="Project End Date"
              className="mb-3"
            >
              <Form.Control
                type="date"
                placeholder="Project End Date"
                required
              />
            </FloatingLabel>
          </Col>
        </Row>
        <h4>Contact Information</h4>
        <hr />
        <p>
          Contact information should the Committee wish to contact you for
          additional information
        </p>
        <Row>
          <Col>
            <FloatingLabel
              controlId="floatingInput"
              label="Landline Phone"
              className="mb-3"
            >
              <Form.Control type="text" placeholder="Landline Phone" required />
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel
              controlId="floatingInput"
              label="Work Phone"
              className="mb-3"
            >
              <Form.Control type="text" placeholder="Work Phone" required />
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel
              controlId="floatingInput"
              label="Cell Phone"
              className="mb-3"
            >
              <Form.Control type="text" placeholder="Cell Phone" required />
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col>
            <FloatingLabel
              controlId="floatingInput"
              label="Mailing Address (if different from above)"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Mailing Address (if different from above)"
              />
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel
              controlId="floatingInput"
              label="Zipcode"
              className="mb-3"
            >
              <Form.Control type="text" placeholder="Zipcode" />
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col>
            <FloatingLabel
              controlId="floatingInput"
              label="Email Address"
              className="mb-3"
            >
              <Form.Control type="text" placeholder="Email Address" required />
            </FloatingLabel>
          </Col>
        </Row>
        <h4>Modification or Addition Requesting</h4>
        <hr />
        {["checkbox"].map((type) => (
          <Row key={`inline-${type}`} className="mb-3">
            <Container>
              <Row>
                <Col>
                  <Form.Check
                    inline
                    label="Detached Structure/Building"
                    name="modification"
                    type={type}
                    id={`inline-${type}-1`}
                  />
                </Col>
                <Col>
                  <Form.Check
                    inline
                    label="Solar Screen(s)"
                    name="modification"
                    type={type}
                    id={`inline-${type}-2`}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Check
                    inline
                    label="Fence"
                    name="modification"
                    type={type}
                    id={`inline-${type}-1`}
                  />
                </Col>
                <Col>
                  <Form.Check
                    inline
                    label="Exterior Modifications"
                    name="modification"
                    type={type}
                    id={`inline-${type}-2`}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Check
                    inline
                    label="New Construction"
                    name="modification"
                    type={type}
                    id={`inline-${type}-1`}
                  />
                </Col>
                <Col>
                  <Form.Check
                    inline
                    label="Landscaping"
                    name="modification"
                    type={type}
                    id={`inline-${type}-2`}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Check
                    inline
                    label="Paint"
                    name="modification"
                    type={type}
                    id={`inline-${type}-1`}
                  />
                </Col>
                <Col>
                  <Form.Check
                    inline
                    label="Shed"
                    name="modification"
                    type={type}
                    id={`inline-${type}-2`}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Check
                    inline
                    label="Roof"
                    name="modification"
                    type={type}
                    id={`inline-${type}-1`}
                  />
                </Col>
                <Col>
                  <Form.Check
                    inline
                    label="Patio/Arbor/Deck"
                    name="modification"
                    type={type}
                    id={`inline-${type}-2`}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Check
                    inline
                    label="Retaining Wall"
                    name="modification"
                    type={type}
                    id={`inline-${type}-1`}
                  />
                </Col>
                <Col>
                  <Form.Check
                    inline
                    label="Pool/Spa"
                    name="modification"
                    type={type}
                    id={`inline-${type}-2`}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Check
                    inline
                    label="Storm Door"
                    name="modification"
                    type={type}
                    id={`inline-${type}-1`}
                  />
                </Col>
                <Col>
                  <InputGroup className="mb-3">
                    <InputGroup.Checkbox
                      checked={checked}
                      onChange={() => {
                        if (checked) {
                          setText("");
                        }
                        setChecked(!checked);
                      }}
                    />
                    <FormControl
                      placeholder="Other"
                      name="input"
                      type="text"
                      disabled={!checked}
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                    />
                  </InputGroup>
                </Col>
              </Row>
            </Container>
          </Row>
        ))}
        <Row>
          <Col>
            <FloatingLabel
              controlId="floatingInput"
              label="Description of and reason for request"
              className="mb-3"
            >
              <Form.Control
                type="textarea"
                style={{ height: "200px" }}
                placeholder="Description of and reason for request"
                required
              />
            </FloatingLabel>
          </Col>
        </Row>
        {["radio"].map((type) => (
          <Row>
            <Col>
              <p>Is this request in response to violation letter? </p>
            </Col>
            <Col>
              <Form.Check
                inline
                type={type}
                label="Yes"
                id={`default-${type}`}
              />

              <Form.Check
                inline
                type={type}
                label="No"
                id={`default-${type}`}
              />
            </Col>
          </Row>
        ))}
        <h4>Owners Acknowledgements</h4>
        <p>I understand:</p>
        <ul>
          <li>
            That no work on this request shall commernce until I have received
            approval of the Architectural Control Committee(ACC);
          </li>
          <li>
            Any construction or alteration to the subject property prior to
            approval of the Architectural Control Committee is strictly
            prohibited. If I have commenced or completed any construction or
            alteration to the subject property and any part of this application
            is disapproved, I may be required to return the subject property to
            its original condition at MY OWN EXPENSE. If I refuse to do so and
            the HOA incurs any legal fees related to my construction and/or
            application, I will reimburse the HOA for all such legal expenses
            incurred.
          </li>
          <li>
            That any approval is contingent upon construction or alterations
            being completed in a neat and orderly manner.
          </li>
          <li>
            That there are architectural requirements covered by the Covenants
            and a board review process as established by the Board of Directors.
          </li>
          <li>
            All proposed improvements to the property must comply with city,
            county, state and local codes. I understand that applications for
            all required building permits are my responsibility. Nothing herein
            shall be construed as a waiver of any modification of any codes. My
            signature indicates that these standards are met to the best of my
            knowledge.
          </li>
          <li>
            That any variation from the original application must be resubmitted
            for approval.
          </li>
          <li>
            That if approved, said alteration must be maintained per the
            Declaration of Covenants, Conditions and Restrictions for the HOA.
          </li>
          <li>
            This alteration will not detrimentally affect the proper drainage of
            any common areas or surrounding lots. I will be responsible at my
            expense to correct any drainage problems to such area that may occur
            as a result of this work or alteration.
          </li>
          <li>
            The Builder/Applicant acknowledges and agrees that the Committee and
            Association assume no liability resulting from the approval or
            disapproval of any plans submitted. The Committee and the
            Association assume no liability and make no representations
            regarding the adequacy or quality of any submitted plans or whether
            such plans comply with any or all governing authority requirements.
            The Committee’s review, comments, and/or approvals do not relieve
            the Builder/Applicant of their responsibility and obligation to
            comply with the Master Declaration, Master Design Guidelines, or
            Subdivision Guidelines as applicable. The Builder/Application agrees
            to grant the Association access to property at any reasonable hour
            to inspect for compliance issues.
          </li>
          <li>
            It is the duty of the owner and the contractor employed by the owner
            to determine that the proposed improvement is structurally,
            mechanically, and otherwise safe and that it is designed and
            constructed in compliance with applicable building codes, fire
            codes, other laws or regulations and sound practices. Your
            association, the ACC and any employee or member thereof, shall not
            be liable in damages or otherwise because of the approval or
            non-approval of any improvement.
          </li>
        </ul>
        <p>
          I certify that the above information is an accurate representation of
          the proposed improvement and that the work will conform to applicable
          codes, covenants and standards. I also certify that the improvements
          will be completed in accordance with the approved application. I
          understand that construction is not to begin until approval has been
          received from the Architectural Control Committee. The Architectural
          Control Committee has permission to enter the property to make
          inspections, as they deem necessary.
        </p>
        <Button type="submit">Submit Form</Button>
      </Form>
    </Container>
  );
}
