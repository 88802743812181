import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import TopNavBar from "./Components/NavBar/TopNavBar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./Components/NavBar/Footer";
import FAQ from "./Components/Pages/FAQ";
import NotFound from "./Components/Pages/NotFound";
import Paint from "./Components/Pages/Paints";
import Board from "./Components/Pages/Board";
import Deed from "./Components/Pages/Deed";
import Bylaws from "./Components/Pages/Bylaws";
import Contact from "./Components/Forms/Contact";
import Complaint from "./Components/Forms/Complaint";
import Estoppel from "./Components/Forms/Estoppel";
import Architectural from "./Components/Forms/Architectural";
import Join from "./Components/Forms/Join";
import ReactGA from "react-ga4";
import Rules from "./Components/Pages/Rules";
import April from "./Components/Minutes/April23";
import May from "./Components/Minutes/May23";
import MayRegular from "./Components/Minutes/MayRegular";
import June23 from "./Components/Minutes/June23";
import July23 from "./Components/Minutes/July23";
import Octobter23 from "./Components/Minutes/Octobter23";
import November23 from "./Components/Minutes/November23";
import December23 from "./Components/Minutes/December23";
import Jan24 from "./Components/Minutes/Jan24";

ReactGA.initialize("G-3464ETLY77");
ReactGA.send("pageview");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <TopNavBar />
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<App />} />
      <Route path="/hoa-meeting-rules" element={<Rules />} />
      <Route path="/april-2023" element={<April />} />
      <Route path="/may-2023" element={<May />} />
      <Route path="/may-2023-regular" element={<MayRegular />} />
      <Route path="/june-2023" element={<June23 />} />
      <Route path="/july-2023" element={<July23 />} />
      <Route path="/october-2023" element={<Octobter23 />} />
      <Route path="/november-2023" element={<November23 />} />
      <Route path="/december-2023" element={<December23 />} />
      <Route path="/january-2024" element={<Jan24 />} />
      <Route path="/frequently-asked-questions" element={<FAQ />} />
      <Route path="/approved-exterior-paint-colors" element={<Paint />} />
      <Route path="/board-members" element={<Board />} />
      <Route path="/deed-restrictions" element={<Deed />} />
      <Route path="/bylaws" element={<Bylaws />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/complaint" element={<Complaint />} />
      <Route path="/estoppel-request" element={<Estoppel />} />
      <Route path="/architectural" element={<Architectural />} />
      <Route path="/member-application" element={<Join />} />
    </Routes>
    <Footer />
  </BrowserRouter>
);
